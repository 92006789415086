import React from 'react';
import './App.css';
import Home from './pages/Home';
import UserGuide from './pages/UserGuide';
import DiseaseSymptom from './pages/DiseaseSymptom';
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/user-guide" element={<UserGuide />} />
        <Route path="/disease-symptom" element={<DiseaseSymptom />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router >
  );
}

export default App;
