import React from 'react';
import '../App.css';
import HomePage from '../asserts/homepage.png'
import Header from '../components/Header';

const home = <img src={HomePage} style={{ width: '100%', height: '100%', marginTop: '-20px' }} />
const user_guide = <iframe src="http://www.yybzn.cn/user-guide/user-guide.pdf" style={{ width: '100vw', height: '100vh', marginTop: '-20px' }} />;
const disease_symptom = <iframe src="http://www.yybzn.cn/disease-symptom/diseases-symptoms.pdf" style={{ width: '100vw', height: '100vh', marginTop: '-20px' }} />;

export default function Home() {
    const [content, setContent] = React.useState('首页');
    const [main, setMain] = React.useState(home);
    console.log(content)

    React.useEffect(() => {
        content === '首页' && setMain(home);
        content === '软件使用说明' && setMain(user_guide);
        content === '常见疾病和症状' && setMain(disease_symptom);
    }, [content])

    return (
        <>
            <Header setContent={setContent} />
            <div style={{ width: '100%', height: '100%' }}>
                {main}
            </div>
        </>

    )
}