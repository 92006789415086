import Header from '../components/Header';

import DiseaseSymptom1 from '../asserts/diseases-symptoms/diseases-symptoms-1.jpg';
import DiseaseSymptom2 from '../asserts/diseases-symptoms/diseases-symptoms-2.jpg';
import DiseaseSymptom3 from '../asserts/diseases-symptoms/diseases-symptoms-3.jpg';

export default function diseaseSymptom() {


    return (
        <>
            <Header />
            <img src={DiseaseSymptom1} />
            <img src={DiseaseSymptom2} />
            <img src={DiseaseSymptom3} />
        </>
    )
}