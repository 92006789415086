import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import YaOEPlus_Icon from '../asserts/appLogo_about.png';
import CompanyName from '../asserts/company_name.png'

// import AboutUS from '../asserts/about-us.pdf'
export default function Header(props: any) {

    const handleClick = ((event: any) => {
        // console.log(event.target.innerText)
        props.setContent(event.target.innerText)
    })
    return (
        <Box sx={{
            flexGrow: 1,
            width: '100vw', height: '10vh', backgroundColor: '#fff'
        }}
        >
            <AppBar position="static"
                sx={{
                    backgroundColor: '#fff',
                    width: '100vw',
                    height: '8vh',
                    fontSize: 20,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 'bold',
                    color: '#333333',
                    lineHeight: 30,
                    display: 'flex', justifyContent: 'space-around',
                }}
            >
                <Toolbar>
                    <Box sx={{
                        display: 'flex',
                        backgroundColor: '#fff',
                        width: '10vw',
                        height: '6vh',
                        fontSize: 20,
                        fontFamily: 'Microsoft YaHei',
                        fontWeight: 'bold',
                        color: '#333333',
                        lineHeight: 30,
                        flexGrow: 1,
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            sx={{ mr: 0, width: '4vh', height: '4vh' }}
                        >
                            <img src={YaOEPlus_Icon} style={{ width: '4vh', height: '4vh' }} />
                        </IconButton>
                        <img src={CompanyName} style={{ height: '4vh', width: '20vh' }} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            flexGrow: 1,
                        }}
                    >
                        <Button
                            variant="text"
                            color="secondary"
                            id="home"
                            onClick={handleClick}
                            sx={{ color: '#000' }}
                        >
                            <Typography variant="h6" component="div" sx={{
                                flexGrow: 1
                            }}>
                                首页
                            </Typography>
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            id="user-guide"
                            sx={{ color: '#000' }}
                            // href="user-guide"
                            onClick={handleClick}
                        >
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#000' }}>
                                软件使用说明
                            </Typography>
                        </Button>
                        <Button
                            variant="text"
                            // href="disease-symptom"
                            color="secondary"
                            id="disease-symptom"
                            sx={{ color: '#000' }} onClick={handleClick}
                        >
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#000' }}>
                                常见疾病和症状
                            </Typography>
                        </Button>

                        <Button color="secondary" sx={{ color: '#000' }}>
                            <Link href="http://app.yybzn.cn/" underline="hover">
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#000' }}>
                                    药店登录
                                </Typography>
                            </Link>
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            sx={{ color: '#000' }}
                            onClick={() => { alert("客服电话:18513731889") }}
                        >
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#000' }}>
                                联系我们
                            </Typography>
                        </Button>

                    </Box>
                </Toolbar>
            </AppBar >
        </Box >
    );
}
