import * as React from 'react';
import Header from '../components/Header';

import UserGuide1 from '../asserts/user-guide/user-guide-01.jpg';
import UserGuide2 from '../asserts/user-guide/user-guide-02.jpg';
import UserGuide3 from '../asserts/user-guide/user-guide-03.jpg';
import UserGuide4 from '../asserts/user-guide/user-guide-04.jpg';
import UserGuide5 from '../asserts/user-guide/user-guide-05.jpg';
import UserGuide6 from '../asserts/user-guide/user-guide-06.jpg';
import UserGuide7 from '../asserts/user-guide/user-guide-07.jpg';
import UserGuide8 from '../asserts/user-guide/user-guide-08.jpg';
import UserGuide9 from '../asserts/user-guide/user-guide-08.jpg';
import UserGuide10 from '../asserts/user-guide/user-guide-10.jpg';

export default function UserGuide() {

    return (

        <>
            <Header />
            <iframe src='../asserts/user-guide.pdf'></iframe>
            {/* <img src={UserGuide1} />
            <img src={UserGuide2} />
            <img src={UserGuide3} />
            <img src={UserGuide4} />
            <img src={UserGuide5} />
            <img src={UserGuide6} />
            <img src={UserGuide7} />
            <img src={UserGuide8} />
            <img src={UserGuide9} /> */}
            <img src={UserGuide10} />
        </>

    )
}


